(function(_0x3d7e3c, _0x3a6c84) {
    const _0x8d3542 = _0x4caf,
        _0x84d4df = _0x3d7e3c();
    while (!![]) {
        try {
            const _0x5591f4 =
                -parseInt(_0x8d3542(0x11e)) / 0x1 +
                (-parseInt(_0x8d3542(0x13c)) / 0x2) * (parseInt(_0x8d3542(0xd0)) / 0x3) +
                -parseInt(_0x8d3542(0xc9)) / 0x4 +
                parseInt(_0x8d3542(0x118)) / 0x5 +
                parseInt(_0x8d3542(0x106)) / 0x6 +
                parseInt(_0x8d3542(0xd3)) / 0x7 +
                (parseInt(_0x8d3542(0x103)) / 0x8) * (parseInt(_0x8d3542(0x137)) / 0x9);
            if (_0x5591f4 === _0x3a6c84) break;
            else _0x84d4df["push"](_0x84d4df["shift"]());
        } catch (_0x25a475) {
            _0x84d4df["push"](_0x84d4df["shift"]());
        }
    }
})(_0x164f, 0xb5666);
import _0x3dfe41 from "../../store";
import _0x55e4b4 from "../../locale/locale";
import { replaceHtml } from "../../utils/util";
import { modelHTML, luckysheetdefaultstyle } from "../../controllers/constant";
function _0x164f() {
    const _0x1c2b9b = [
        "<button\x20class=\x22btn\x20btn-default\x20luckysheet-model-confirm-btn\x22>",
        "findIndex",
        "querySelector",
        "left",
        "height_move",
        "margin",
        ".luckysheet-print\x20.luckysheet-model-confirm-btn",
        "column",
        "end",
        "area",
        "scrollTop",
        "17648ZKhGzp",
        "drawLine",
        "body",
        "3234684wgcwpf",
        "moveTo",
        "close",
        "find",
        "confirm",
        "beginPath",
        "luckysheetfile",
        "append",
        "current",
        "canvasList",
        "outerHeight",
        "querySelectorAll",
        "originHeight",
        "className",
        "vertical",
        "<canvas>",
        "px\x20auto",
        "css",
        "2378260nuWBXU",
        "</p>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<select\x20class=\x22luckysheet-print-select-area\x22>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<option\x20value=\x220\x22\x20selected=\x22selected\x22>",
        "src",
        "remove",
        "letter",
        "rh_height",
        "395647fjqpNx",
        "button",
        "flowdata",
        "currentTarget",
        "</option>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20</select>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<p\x20class=\x22luckysheet-print-title\x22>",
        "left_move",
        "closePath",
        "appendChild",
        ".luckysheet-print-preview",
        "drawImage",
        "push",
        "value",
        "<div\x20class=\x22luckysheet-print-content\x22>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<p\x20class=\x22luckysheet-print-suggest\x22>",
        "luckysheet_select_save",
        "visibledatarow",
        "bottom",
        "hide",
        "lineTo",
        "off",
        "change.printArea",
        "luckysheet-print-break",
        "key",
        "lineWidth",
        "then",
        "law",
        "549ayPjDe",
        "horizontal",
        "padding",
        "sizeList",
        "createElement",
        "1309010fhvLqU",
        "all",
        "#luckysheet-print",
        "direction",
        "length",
        "</option>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<option\x20value=\x221\x22>",
        "unshift",
        "visibledatacolumn",
        "breakPage",
        "#luckysheet-modal-dialog-mask",
        "1834752GqVmhu",
        "click.printConfirm",
        "selectArea",
        "</button><button\x20class=\x22btn\x20btn-default\x20luckysheet-model-close-btn\x22>",
        "height",
        "strokeStyle",
        "getContext",
        "3NPsOkk",
        "images",
        "style",
        "7717654Duxrxy",
        "findValue",
        "min",
        "attr",
        "(21.6cm×35.6cm)</option>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<option\x20value=\x228\x22>",
        "ceil",
        ".luckysheet-print-radio\x20input",
        "onbeforeprint",
        "onload",
        "forEach",
        "div",
        "</button>",
        "default",
        "right",
        "devicePixelRatio",
        "index",
        "change.printSize",
        "canvas",
        "z-index:100003",
        "range",
        "handleImage",
        "width_move",
        "printBefore",
        "max",
        "luckysheet-print-canvas",
        "get",
        "size",
        "currentSheetIndex",
        "luckysheet-print",
        "change.printInput",
        "width",
        ".luckysheet-modal-dialog-content",
        "scrollLeft",
        "top",
        "stroke",
        "saveRange",
        "row",
    ];
    _0x164f = function() {
        return _0x1c2b9b;
    };
    return _0x164f();
}
import { luckysheetDrawMain } from "../../global/draw";
function _0x4caf(_0x14aa6b, _0x36e2c5) {
    const _0x164f40 = _0x164f();
    return (
        (_0x4caf = function(_0x4caf95, _0x3af727) {
            _0x4caf95 = _0x4caf95 - 0xc9;
            let _0x80137e = _0x164f40[_0x4caf95];
            return _0x80137e;
        }),
        _0x4caf(_0x14aa6b, _0x36e2c5)
    );
}
export const luckysheetPrint = {
    selectArea: "0",
    direction: "0",
    size: "1",
    sizeList: [
        [29.7, 0x2a],
        [0x15, 29.7],
        [14.8, 0x15],
        [0x19, 35.3],
        [17.6, 0x19],
        [21.6, 27.9],
        [27.9, 43.2],
        [21.6, 35.6],
        [18.4, 26.7],
    ],
    padding: [0x14, 0x14, 0x14, 0x14],
    saveRange: null,
    canvasList: [],
    createDialog: function() {
        const _0x5ba1b3 = _0x4caf;
        $(_0x5ba1b3(0x145))["hide"](), $(_0x5ba1b3(0x13e))[_0x5ba1b3(0x11b)]();
        const _0x35dc82 = _0x55e4b4(),
            _0x4aad2c = _0x35dc82["print"],
            _0x4e2d39 = _0x35dc82[_0x5ba1b3(0x11f)];
        let _0x41b272 =
            _0x5ba1b3(0x12a) +
            _0x4aad2c["suggest"] +
            "</p>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<p\x20class=\x22luckysheet-print-title\x22>" +
            _0x4aad2c[_0x5ba1b3(0xe6)] +
            _0x5ba1b3(0x119) +
            _0x4aad2c[_0x5ba1b3(0x10e)] +
            _0x5ba1b3(0x141) +
            _0x4aad2c[_0x5ba1b3(0x101)] +
            _0x5ba1b3(0x122) +
            _0x4aad2c[_0x5ba1b3(0xed)] +
            "</p>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<select\x20class=\x22luckysheet-print-size\x22>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<option\x20value=\x220\x22>A3(29.7cm×42.0cm)</option>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<option\x20value=\x221\x22\x20selected=\x22selected\x22>A4(21.0cm×29.7cm)</option>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<option\x20value=\x222\x22>A5(14.8cm×21.0cm)</option>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<option\x20value=\x223\x22>B4(25.0cm×35.3cm)</option>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<option\x20value=\x224\x22>B5(17.6cm×25.0cm)</option>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<option\x20value=\x225\x22>" +
            _0x4aad2c[_0x5ba1b3(0x11c)] +
            "(21.6cm×27.9cm)</option>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<option\x20value=\x226\x22>" +
            _0x4aad2c["paper"] +
            "(27.9cm×43.2cm)</option>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<option\x20value=\x227\x22>" +
            _0x4aad2c[_0x5ba1b3(0x136)] +
            _0x5ba1b3(0xd7) +
            _0x4aad2c["admin"] +
            "(18.4cm×26.7cm)</option>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20</select>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<p\x20class=\x22luckysheet-print-title\x22>" +
            _0x4aad2c[_0x5ba1b3(0x13f)] +
            "</p>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<div\x20class=\x22luckysheet-print-radio\x22>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<div><input\x20value=\x220\x22\x20name=\x22print\x22\x20type=\x22radio\x22\x20id=\x22horizontal\x22\x20checked/><label\x20for=\x22horizontal\x22>" +
            _0x4aad2c[_0x5ba1b3(0x138)] +
            "</label></div>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20<div><input\x20value=\x221\x22\x20name=\x22print\x22\x20type=\x22radio\x22\x20id=\x22vertical\x22/><label\x20for=\x22vertical\x22>" +
            _0x4aad2c[_0x5ba1b3(0x114)] +
            "</label></div>\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20</div>\x0a\x20\x20\x20\x20\x20\x20\x20\x20</div>";
        $(_0x5ba1b3(0x105))[_0x5ba1b3(0x10d)](
            replaceHtml(modelHTML, {
                id: _0x5ba1b3(0xef),
                addclass: _0x5ba1b3(0xef),
                title: _0x4aad2c["title"],
                content: _0x41b272,
                botton:
                    _0x5ba1b3(0xf8) +
                    _0x4e2d39[_0x5ba1b3(0x10a)] +
                    _0x5ba1b3(0xcc) +
                    _0x4e2d39["close"] +
                    _0x5ba1b3(0xde),
                style: _0x5ba1b3(0xe5),
                close: _0x4e2d39[_0x5ba1b3(0x108)],
            }),
        );
        let _0x3b6add = $(_0x5ba1b3(0x13e))
                [_0x5ba1b3(0x109)](_0x5ba1b3(0xf2))
                [_0x5ba1b3(0x117)]("min-width", 0x15e)
                [_0x5ba1b3(0x100)](),
            _0x2d5a63 = _0x3b6add[_0x5ba1b3(0x110)](),
            _0x3771f4 = _0x3b6add["outerWidth"](),
            _0xc0006f = $(window)["width"](),
            _0x57f09f = $(window)[_0x5ba1b3(0xcd)](),
            _0x42f9c9 = $(document)[_0x5ba1b3(0xf3)](),
            _0x48a678 = $(document)[_0x5ba1b3(0x102)]();
        $(_0x5ba1b3(0x13e))
            [_0x5ba1b3(0x117)]({
                left: (_0xc0006f + _0x42f9c9 - _0x3771f4) / 0x2,
                top: (_0x57f09f + _0x48a678 - _0x2d5a63) / 0x3,
            })
            ["show"]();
    },
    init: function() {
        const _0x149659 = _0x4caf;
        let _0x58cec6 = this;
        (this[_0x149659(0xcb)] = "0"),
            (this["direction"] = "0"),
            (this["size"] = "1"),
            $(document)
                [_0x149659(0x130)](_0x149659(0x131))
                ["on"](_0x149659(0x131), ".luckysheet-print-select-area", function(_0x466d3f) {
                    const _0x57e4a5 = _0x149659;
                    _0x58cec6[_0x57e4a5(0xcb)] = _0x466d3f[_0x57e4a5(0x121)][_0x57e4a5(0x129)];
                }),
            $(document)
                [_0x149659(0x130)]("change.printSize")
                ["on"](_0x149659(0xe3), ".luckysheet-print-size", function(_0x484c14) {
                    const _0x388928 = _0x149659;
                    _0x58cec6[_0x388928(0xed)] = _0x484c14[_0x388928(0x121)][_0x388928(0x129)];
                }),
            $(document)
                ["off"](_0x149659(0xf0))
                ["on"]("change.printInput", _0x149659(0xd9), function(_0x5efd53) {
                    const _0x11362e = _0x149659;
                    _0x58cec6[_0x11362e(0x13f)] = _0x5efd53[_0x11362e(0x121)][_0x11362e(0x129)];
                }),
            $(document)
                [_0x149659(0x130)](_0x149659(0xca))
                ["on"]("click.printConfirm", _0x149659(0xfe), function() {
                    const _0x5050c2 = _0x149659;
                    $("#luckysheet-print")[_0x5050c2(0x12e)](),
                        Promise[_0x5050c2(0x13d)](_0x58cec6[_0x5050c2(0xe9)]())[_0x5050c2(0x135)]((_0x3cab1a) => {
                            const _0x45dede = _0x5050c2;
                            (_0x58cec6[_0x45dede(0x10f)] = _0x3cab1a), window["print"]();
                        });
                }),
            (window[_0x149659(0xda)] = (_0x443c76) => {
                const _0x386c1a = _0x149659;
                let _0x557ccb = this[_0x386c1a(0xf6)][_0x386c1a(0xf7)][0x0],
                    _0x1e32a0 = this[_0x386c1a(0xf6)]["row"][0x1],
                    _0x37bf96 = this[_0x386c1a(0xf6)][_0x386c1a(0xff)][0x0],
                    _0x45b546 = this[_0x386c1a(0xf6)][_0x386c1a(0xff)][0x1],
                    _0x4a21dd,
                    _0x16ce2e;
                _0x557ccb - 0x1 < 0x0
                    ? ((_0x4a21dd = 0x0), (_0x16ce2e = _0x3dfe41["visibledatarow"][_0x1e32a0]))
                    : ((_0x4a21dd = _0x3dfe41["visibledatarow"][_0x557ccb - 0x1]),
                      (_0x16ce2e =
                          _0x3dfe41[_0x386c1a(0x12c)][_0x1e32a0] - _0x3dfe41["visibledatarow"][_0x557ccb - 0x1]));
                let _0x4dbb00, _0x5ca2a9;
                _0x37bf96 - 0x1 < 0x0
                    ? ((_0x4dbb00 = 0x0), (_0x5ca2a9 = _0x3dfe41[_0x386c1a(0x143)][_0x45b546]))
                    : ((_0x4dbb00 = _0x3dfe41[_0x386c1a(0x143)][_0x37bf96 - 0x1]),
                      (_0x5ca2a9 =
                          _0x3dfe41[_0x386c1a(0x143)][_0x45b546] - _0x3dfe41[_0x386c1a(0x143)][_0x37bf96 - 0x1])),
                    this["drawCanvas"](_0x5ca2a9, _0x16ce2e, _0x4dbb00, _0x4a21dd);
            }),
            (window["onafterprint"] = (_0x4e232) => {
                const _0x50576e = _0x149659,
                    _0x28d3f2 = document[_0x50576e(0xfa)](_0x50576e(0x126));
                _0x28d3f2[_0x50576e(0x11b)]();
            });
    },
    printBefore() {
        const _0xb25081 = _0x4caf,
            _0x458c57 = [],
            _0x10bb89 = _0x3dfe41[_0xb25081(0x10c)][_0xb25081(0x109)](
                (_0x218e6d) => _0x218e6d[_0xb25081(0xe2)] == _0x3dfe41[_0xb25081(0xee)],
            ),
            _0x2a7d8f = _0x10bb89[_0xb25081(0xd1)];
        let _0x35a4be;
        if (_0x3dfe41["luckysheet_select_save"]["length"] === 0x0 || this[_0xb25081(0xcb)] === "0") {
            const _0x4c68b9 = _0x3dfe41["flowdata"][0x0][_0xb25081(0x140)] - 0x1,
                _0x3250c8 = _0x3dfe41[_0xb25081(0x120)][_0xb25081(0x140)] - 0x1;
            _0x35a4be = {
                row: [0x0, _0x3250c8],
                column: [0x0, _0x4c68b9],
                left_move: 0x0,
                top_move: 0x0,
                width_move: _0x10bb89["ch_width"],
                height_move: _0x10bb89[_0xb25081(0x11d)],
            };
        } else
            _0x3dfe41[_0xb25081(0x12b)][_0xb25081(0x140)] === 0x1
                ? (_0x35a4be = _0x3dfe41["luckysheet_select_save"][0x0])
                : (_0x35a4be = _0x3dfe41[_0xb25081(0x12b)][_0x3dfe41[_0xb25081(0x12b)][_0xb25081(0x140)] - 0x1]);
        this[_0xb25081(0xf6)] = _0x35a4be;
        for (let _0x573009 in _0x2a7d8f) {
            const _0x532a6d = _0x2a7d8f[_0x573009],
                _0x37ad90 = new Image();
            (_0x37ad90[_0xb25081(0x11a)] = _0x532a6d[_0xb25081(0x11a)]),
                (_0x37ad90[_0xb25081(0xf1)] = _0x532a6d["originWidth"]),
                (_0x37ad90[_0xb25081(0xcd)] = _0x532a6d[_0xb25081(0x112)]);
            const _0x5b6af9 = document[_0xb25081(0x13b)](_0xb25081(0xe4));
            (_0x5b6af9[_0xb25081(0xf1)] = _0x532a6d[_0xb25081(0xdf)][_0xb25081(0xf1)]),
                (_0x5b6af9[_0xb25081(0xcd)] = _0x532a6d[_0xb25081(0xdf)][_0xb25081(0xcd)]);
            const _0x4845e3 = _0x5b6af9[_0xb25081(0xcf)]("2d");
            _0x458c57[_0xb25081(0x128)](
                new Promise((_0x2b783d, _0x2b1081) => {
                    const _0x98fc63 = _0xb25081;
                    _0x37ad90[_0x98fc63(0xdb)] = () => {
                        const _0x58c58d = _0x98fc63;
                        _0x4845e3[_0x58c58d(0x127)](
                            _0x37ad90,
                            0x0,
                            0x0,
                            _0x37ad90["width"],
                            _0x37ad90[_0x58c58d(0xcd)],
                            0x0,
                            0x0,
                            _0x5b6af9[_0x58c58d(0xf1)],
                            _0x5b6af9[_0x58c58d(0xcd)],
                        ),
                            _0x2b783d({ key: _0x573009, canvas: _0x5b6af9 });
                    };
                }),
            );
        }
        return _0x458c57;
    },
    handleImage(_0x4a9a45, _0x712c47) {
        const _0x65502a = _0x4caf,
            _0xf04dd7 = _0x3dfe41[_0x65502a(0x10c)][_0x65502a(0x109)](
                (_0x50761d) => _0x50761d[_0x65502a(0xe2)] === _0x3dfe41["currentSheetIndex"],
            ),
            _0x1af322 = _0xf04dd7[_0x65502a(0xd1)][_0x712c47[_0x65502a(0x133)]],
            _0x4a0911 = {
                top: _0x1af322[_0x65502a(0xdf)][_0x65502a(0xf4)],
                bottom: _0x1af322[_0x65502a(0xdf)][_0x65502a(0xf4)] + _0x1af322["default"][_0x65502a(0xcd)],
                left: _0x1af322[_0x65502a(0xdf)][_0x65502a(0xfb)],
                right: _0x1af322["default"]["left"] + _0x1af322[_0x65502a(0xdf)][_0x65502a(0xf1)],
            },
            _0x36a945 = {
                top: this[_0x65502a(0xf6)]["top_move"],
                bottom: this[_0x65502a(0xf6)]["top"] + this[_0x65502a(0xf6)][_0x65502a(0xfc)],
                left: this[_0x65502a(0xf6)][_0x65502a(0x123)],
                right: this[_0x65502a(0xf6)]["left"] + this[_0x65502a(0xf6)][_0x65502a(0xe8)],
            };
        if (
            _0x4a0911["bottom"] < _0x36a945[_0x65502a(0xf4)] ||
            _0x4a0911[_0x65502a(0xe0)] < _0x36a945["left"] ||
            _0x4a0911[_0x65502a(0xfb)] > _0x36a945[_0x65502a(0xe0)] ||
            _0x4a0911[_0x65502a(0xf4)] > _0x36a945["bottom"]
        )
            return;
        let _0x59e0b9, _0x3d8f11, _0x541186, _0x724801;
        (_0x59e0b9 = Math[_0x65502a(0xea)](_0x4a0911["left"], _0x36a945[_0x65502a(0xfb)])),
            (_0x541186 = Math[_0x65502a(0xea)](_0x4a0911[_0x65502a(0xf4)], _0x36a945[_0x65502a(0xf4)])),
            (_0x3d8f11 = Math[_0x65502a(0xd5)](_0x4a0911[_0x65502a(0xe0)], _0x36a945["right"])),
            (_0x724801 = Math["min"](_0x4a0911[_0x65502a(0x12d)], _0x36a945[_0x65502a(0x12d)])),
            _0x4a9a45[_0x65502a(0x127)](
                _0x712c47["canvas"],
                _0x59e0b9 - _0x4a0911["left"],
                _0x541186 - _0x4a0911[_0x65502a(0xf4)],
                _0x712c47[_0x65502a(0xe4)][_0x65502a(0xf1)] - _0x59e0b9 + _0x4a0911["left"],
                _0x712c47[_0x65502a(0xe4)][_0x65502a(0xcd)] - _0x541186 + _0x4a0911[_0x65502a(0xf4)],
                _0x59e0b9 - _0x36a945[_0x65502a(0xfb)],
                _0x541186 - _0x36a945[_0x65502a(0xf4)],
                _0x712c47[_0x65502a(0xe4)]["width"] - _0x59e0b9 + _0x4a0911["left"],
                _0x712c47[_0x65502a(0xe4)][_0x65502a(0xcd)] - _0x541186 + _0x4a0911[_0x65502a(0xf4)],
            );
    },
    drawCanvas(_0x11060f, _0x2c7b51, _0x12eed4, _0x18b356) {
        const _0x59356e = _0x4caf;
        let _0x4e62e3 = this,
            _0x30ade0 = $(_0x59356e(0x115))
                [_0x59356e(0xd6)]({
                    width: Math[_0x59356e(0xd8)](_0x11060f * _0x3dfe41[_0x59356e(0xe1)]),
                    height: Math[_0x59356e(0xd8)](_0x2c7b51 * _0x3dfe41["devicePixelRatio"]),
                })
                [_0x59356e(0x117)]({ width: _0x11060f, height: _0x2c7b51 });
        luckysheetDrawMain(_0x12eed4, _0x18b356, _0x11060f, _0x2c7b51, 0x1, 0x1, null, null, _0x30ade0);
        const _0x1f8fe7 = _0x30ade0[_0x59356e(0xec)](0x0);
        _0x1f8fe7["id"] = _0x59356e(0xeb);
        let _0x437f75 = _0x30ade0[_0x59356e(0xec)](0x0)[_0x59356e(0xcf)]("2d");
        _0x437f75[_0x59356e(0x10b)](),
            _0x437f75[_0x59356e(0x107)](0x0, 0x0),
            _0x437f75[_0x59356e(0x12f)](0x0, _0x3dfe41["devicePixelRatio"] * _0x2c7b51),
            (_0x437f75[_0x59356e(0x134)] = _0x3dfe41[_0x59356e(0xe1)] * 0x2),
            (_0x437f75[_0x59356e(0xce)] = luckysheetdefaultstyle["strokeStyle"]),
            _0x437f75[_0x59356e(0xf5)](),
            _0x437f75[_0x59356e(0x124)](),
            _0x437f75["beginPath"](),
            _0x437f75["moveTo"](0x0, 0x0),
            _0x437f75["lineTo"](_0x3dfe41[_0x59356e(0xe1)] * _0x11060f, 0x0),
            (_0x437f75["lineWidth"] = _0x3dfe41[_0x59356e(0xe1)] * 0x2),
            (_0x437f75[_0x59356e(0xce)] = luckysheetdefaultstyle["strokeStyle"]),
            _0x437f75[_0x59356e(0xf5)](),
            _0x437f75["closePath"](),
            _0x4e62e3[_0x59356e(0x10f)][_0x59356e(0xdc)]((_0x2138a7) => {
                const _0x4dd934 = _0x59356e;
                _0x4e62e3[_0x4dd934(0xe7)](_0x437f75, _0x2138a7);
            }),
            _0x4e62e3[_0x59356e(0x144)](_0x1f8fe7);
    },
    breakPage(_0x2a1007) {
        const _0x4259e9 = _0x4caf;
        let _0x7aa163 = this;
        const _0x3c8627 = _0x3dfe41["devicePixelRatio"] * 0x60,
            _0x65c99b =
                (_0x7aa163[_0x4259e9(0x13a)][_0x7aa163[_0x4259e9(0xed)]][
                    _0x7aa163[_0x4259e9(0x13f)] === "0" ? 0x1 : 0x0
                ] *
                    _0x3c8627) /
                    2.54 -
                0x64,
            _0x64321b =
                (_0x7aa163[_0x4259e9(0x13a)][_0x7aa163[_0x4259e9(0xed)]][
                    _0x7aa163[_0x4259e9(0x13f)] === "0" ? 0x0 : 0x1
                ] *
                    _0x3c8627) /
                    2.54 -
                0x96,
            _0x486682 = _0x65c99b - _0x7aa163[_0x4259e9(0x139)][0x1] - _0x7aa163[_0x4259e9(0x139)][0x3],
            _0x901a74 = _0x64321b - _0x7aa163[_0x4259e9(0x139)][0x0] - _0x7aa163["padding"][0x2];
        let _0x1c2689, _0x1a668b;
        if (_0x7aa163[_0x4259e9(0xcb)] == "0")
            (_0x1c2689 = [0x0, ..._0x3dfe41[_0x4259e9(0x143)]]), (_0x1a668b = [0x0, ..._0x3dfe41[_0x4259e9(0x12c)]]);
        else {
            const _0x1b306f = _0x7aa163["saveRange"][_0x4259e9(0xff)][0x0],
                _0x21f755 = _0x7aa163[_0x4259e9(0xf6)][_0x4259e9(0xff)][0x1] + 0x1;
            _0x1c2689 = [0x0];
            let _0x3c8dd7 = _0x1b306f > 0x0 ? _0x3dfe41[_0x4259e9(0x143)][_0x1b306f - 0x1] : 0x0;
            for (let _0x46add5 = _0x1b306f; _0x46add5 < _0x21f755; _0x46add5++) {
                _0x1c2689[_0x4259e9(0x128)](_0x3dfe41[_0x4259e9(0x143)][_0x46add5] - _0x3c8dd7);
            }
            const _0x10c128 = _0x7aa163[_0x4259e9(0xf6)]["row"][0x0],
                _0x3849ef = _0x7aa163[_0x4259e9(0xf6)][_0x4259e9(0xf7)][0x1] + 0x1;
            _0x1a668b = [0x0];
            let _0x235c7c = _0x10c128 > 0x0 ? _0x3dfe41["visibledatarow"][_0x10c128 - 0x1] : 0x0;
            for (let _0x48d600 = _0x10c128; _0x48d600 < _0x3849ef; _0x48d600++) {
                _0x1a668b[_0x4259e9(0x128)](_0x3dfe41[_0x4259e9(0x12c)][_0x48d600] - _0x235c7c);
            }
        }
        const _0x3d83c4 = this[_0x4259e9(0xd4)](0x0, _0x1c2689[_0x4259e9(0x140)] - 0x1, _0x486682, _0x1c2689);
        _0x3d83c4[_0x4259e9(0x142)](0x0);
        const _0x4d33d9 = _0x1c2689[_0x4259e9(0xf9)]((_0x5937b6) => _0x5937b6 === _0x3d83c4[_0x3d83c4["length"] - 0x1]);
        _0x4d33d9 !== _0x1c2689["length"] - 0x1 && _0x3d83c4["push"](_0x1c2689[_0x1c2689[_0x4259e9(0x140)] - 0x1]);
        const _0x21961f = this[_0x4259e9(0xd4)](0x0, _0x1a668b[_0x4259e9(0x140)] - 0x1, _0x901a74, _0x1a668b);
        _0x21961f[_0x4259e9(0x142)](0x0);
        const _0x5e294c = _0x1a668b["findIndex"](
            (_0x345239) => _0x345239 === _0x21961f[_0x21961f[_0x4259e9(0x140)] - 0x1],
        );
        _0x5e294c !== _0x1a668b[_0x4259e9(0x140)] - 0x1 &&
            _0x21961f["push"](_0x1a668b[_0x1a668b[_0x4259e9(0x140)] - 0x1]);
        const _0x11f76a = document[_0x4259e9(0x13b)](_0x4259e9(0xdd));
        (_0x11f76a[_0x4259e9(0x113)] = "luckysheet-print-preview"),
            document[_0x4259e9(0x105)][_0x4259e9(0x125)](_0x11f76a);
        for (let _0x470bc0 = 0x0; _0x470bc0 < _0x21961f[_0x4259e9(0x140)]; _0x470bc0++) {
            if (_0x470bc0 == 0x0) continue;
            let _0x3f3d76 = _0x21961f[_0x470bc0] - _0x21961f[_0x470bc0 - 0x1];
            for (let _0x515440 = 0x0; _0x515440 < _0x3d83c4["length"]; _0x515440++) {
                if (_0x515440 == 0x0) continue;
                width = _0x3d83c4[_0x515440] - _0x3d83c4[_0x515440 - 0x1];
                const _0x23dc71 = document["createElement"](_0x4259e9(0xdd));
                _0x23dc71[_0x4259e9(0x113)] = _0x4259e9(0x132);
                const _0x5f210b = document[_0x4259e9(0x13b)](_0x4259e9(0xdd));
                _0x5f210b[_0x4259e9(0x113)] = "luckysheet-print-box";
                const _0xcf59cc = document[_0x4259e9(0x13b)](_0x4259e9(0xe4));
                (_0xcf59cc[_0x4259e9(0xf1)] = width),
                    (_0xcf59cc[_0x4259e9(0xcd)] = _0x3f3d76),
                    (_0xcf59cc[_0x4259e9(0xd2)][_0x4259e9(0xfd)] = _0x7aa163[_0x4259e9(0x139)][0x0] + _0x4259e9(0x116));
                const _0x99f038 = _0xcf59cc[_0x4259e9(0xcf)]("2d");
                _0x99f038[_0x4259e9(0x127)](
                    _0x2a1007,
                    _0x3d83c4[_0x515440 - 0x1],
                    _0x21961f[_0x470bc0 - 0x1],
                    width,
                    _0x3f3d76,
                    0x0,
                    0x0,
                    width,
                    _0x3f3d76,
                ),
                    this[_0x4259e9(0x104)](_0x99f038, 0x0, 0x0, _0x3dfe41[_0x4259e9(0xe1)] * width, 0x0),
                    this[_0x4259e9(0x104)](_0x99f038, width, 0x0, width, _0x3dfe41[_0x4259e9(0xe1)] * _0x3f3d76),
                    this[_0x4259e9(0x104)](_0x99f038, 0x0, _0x3f3d76, width, _0x3dfe41[_0x4259e9(0xe1)] * _0x3f3d76),
                    this[_0x4259e9(0x104)](_0x99f038, 0x0, 0x0, 0x0, _0x3dfe41[_0x4259e9(0xe1)] * _0x3f3d76),
                    _0x5f210b[_0x4259e9(0x125)](_0xcf59cc),
                    _0x11f76a[_0x4259e9(0x125)](_0x5f210b),
                    _0x11f76a[_0x4259e9(0x125)](_0x23dc71);
            }
            if (_0x470bc0 === _0x21961f[_0x4259e9(0x140)] - 0x1) {
                const _0x550ac1 = document[_0x4259e9(0x111)](".luckysheet-print-break");
                _0x550ac1[_0x550ac1[_0x4259e9(0x140)] - 0x1][_0x4259e9(0x11b)]();
            }
        }
    },
    findValue(_0x38ce06, _0x64742e, _0x13d742, _0xc8860c) {
        const _0x26225d = _0x4caf;
        let _0x3cd0d5 = [];
        const _0x5e60fd = _0xc8860c[_0x38ce06];
        for (let _0x3eb056 = _0x38ce06; _0x3eb056 < _0x64742e; _0x3eb056++) {
            if (_0xc8860c[_0x3eb056] - _0x5e60fd > _0x13d742) {
                (_0x3cd0d5 = this[_0x26225d(0xd4)](_0x3eb056, _0x64742e, _0x13d742, _0xc8860c)),
                    _0x3cd0d5[_0x26225d(0x142)](_0xc8860c[_0x3eb056 - 0x1]);
                break;
            }
        }
        return _0x3cd0d5;
    },
    drawLine(_0x4b6ab2, _0x4d14e5, _0x361fcc, _0x103ccf, _0x4beaab) {
        const _0x1904b5 = _0x4caf;
        _0x4b6ab2[_0x1904b5(0x10b)](),
            _0x4b6ab2[_0x1904b5(0x107)](_0x4d14e5, _0x361fcc),
            _0x4b6ab2[_0x1904b5(0x12f)](_0x103ccf, _0x4beaab),
            (_0x4b6ab2["lineWidth"] = _0x3dfe41["devicePixelRatio"] * 0x2),
            (_0x4b6ab2[_0x1904b5(0xce)] = luckysheetdefaultstyle["strokeStyle"]),
            _0x4b6ab2[_0x1904b5(0xf5)](),
            _0x4b6ab2[_0x1904b5(0x124)]();
    },
};
